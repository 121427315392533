<template>
  <section>
    <b-row>
      <b-col lg="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ Currency }} </b-card-title>
          </b-card-header>
        </b-card>
      </b-col>
      <b-col lg="12">
        <b-card no-body>
          <b-card-header class="Instrument">
            <b-card-title>期权交易持仓量(标的) </b-card-title>
          </b-card-header>
          <b-card-body>
            <option-position-chart ref="currency" :chartType="3" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="12">
        <b-card no-body>
          <b-card-header class="Instrument">
            <b-card-title>大宗交易</b-card-title>
          </b-card-header>
          <b-card-body>
            <div v-if="isShow" class="noData"><b>该标的名暂无数据!</b></div>
            <div v-if="!isShow">
              <option-position-chart ref="BlockTransactions" :chartType="3" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import { getBtcCurrency } from "@/api/Options.js";
import {
  getBtcOptionsRate,
  getEthOptionsRate,
} from "@/api/BlockTransactions.js";
import optionPositionChart from "@/views/charts-and-maps/charts/echart/option-echart/optionPositionChart.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    optionPositionChart,
  },
  data() {
    return {
      isShow:false,
      ScreenName: "",
      defaultInstrument: "", //当前in标的名
      CurrencytempData: [], //存放标的所有数据
      CurrencyTime: [], //存放标的x轴所有时间
      CurrencytimeData: [],
      inyData: [],
      instrumentsData: [], //存放标的y轴所有数据
      inData: "",
      Currency: "",
      tempData: [], //存放大宗交易所有数据
      XCharData: [],
      YCharData: [],
      tempEthData: [], //存放大宗交易所有数据
    };
  },
  watch: {},
  created() {
    // 那我两个用来存储xy轴数据用同一个？
    this.getCurrencyChartData();
    this.getParams();
    this.XCharData = [];
    this.YCharData = [];
    if (this.$route.query.bc === "BTC") {
      this.getListData(); //BTC
      //console.log("BTCjiekou");
    } else {
      this.getEthListData(); //ETH
      //console.log("ETHjiekou");
    }
  },
  computed: {},
  mounted() {},
  methods: {
    
    getParams() {
      this.$route.query;
      //console.log(this.$route.query);
      this.Currency = this.$route.query.id;
    },
    //期权交易持仓量(标的)
    getCurrencyChartData(val) {
      let params = {
        in: this.$route.query.id,
      };
      this.ScreenName = val;
      //console.log("===", this.ScreenName);
      getBtcCurrency(params)
        .then((res) => {
          let CurrencytempData = res.data.data;

          this.defaultInstrument = res.data.in;
          // console.log("CurrencytimeData:", CurrencytimeData);

          //获取x轴所有时间
          let CurrencytimeData = this.getExpiryTime(CurrencytempData);
          // console.log("CurrencytimeData:", CurrencytimeData);

          //格式化x轴所有时间
          let arr = new Array();
          for (let i = 0; i < CurrencytimeData.length; i++) {
            let day = this.dateFtt(
              "MM-dd hh:mm",
              new Date(CurrencytimeData[i])
            );
            arr.push(day);
          }
          this.CurrencyTime = arr;
          // console.log("this.CurrencyTime:", this.CurrencyTime);

          //获取y轴所有data值
          let instrumentsData = this.getExpiryValue(CurrencytempData);
          // console.log("instrumentsData:", instrumentsData);
          let inyData = [];
          for (let i = 0; i < instrumentsData.length; i++) {
            let item = instrumentsData[i];
            this.inyData.push(parseFloat(item));
          }
          // console.log("<11>:", this.inyData);

          // 获取图表数据xy轴
          this.chartCurrencyData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取大宗期权Deribit BTC期权标的所以数据
    getListData(val) {
      let params = {
        in: this.$route.query.id,
      };
      this.ScreenName = val;
      getBtcOptionsRate(params)
        .then((res) => {
          if (res.data.code == "0") {
            let tempData = res.data.data;
            this.defaultInstrument = res.data.in;
            //console.log("111", tempData);
            this.YCharData = tempData.map((v) =>
              parseFloat(v.price_amount / 10000).toFixed(2)
            );
            //console.log("222", this.YCharData);
            this.XCharData = tempData.map((v) => v.timestamp);
            //console.log("333", this.XCharData);
            // 获取图表数据xy轴
            this.chartData(1);
          }
        })
        .catch((err) => {
          this.isShow = true;
          //console.log("没有数据")
        });
    },
    // 获取大宗期权Deribit ETH期权标的所以数据
    getEthListData(val) {
      let params = {
        in: this.$route.query.id,
      };
      this.ScreenName = val;
      getEthOptionsRate(params)
        .then((res) => {
          if (res.data.code == 0) {
            let tempEthData = res.data.data;
            this.defaultInstrument = res.data.in;
            //console.log("tempEthData", tempEthData);
            this.YCharData = tempEthData.map((v) =>
              parseFloat(v.price_amount / 10000).toFixed(2)
            );
            //console.log("YCharData", this.YCharData);
            this.XCharData = tempEthData.map((v) => v.timestamp);
            //console.log("XCharData", this.XCharData);
            // 获取图表数据xy轴
            this.chartData(1);
          }
        })
        .catch((err) => {
         this.isShow = true;
          //console.log("没有数据")
        });
    },
    //获取 标的 chart XY轴data值
    chartCurrencyData(type) {
      let defaultInstrument = this.defaultInstrument;
      if (type === 1) {
        let ScreenName =
          this.instrument_name && this.instrument_name.length !== 0
            ? this.ScreenName
            : this.defaultInstrument;
        this.$refs.currency.chart.setOption({
          tooltip: {
            align: "axis",
            formatter: function (params) {
              return params[0].value
                ? `<div>${params[0].name}</div>
                <div> ${ScreenName} <br><br> 持仓总量：${params[0].value}</div> `
                : `<div>${params[0].name}</div>
                <div> ${ScreenName} <br><br> 持仓总量：${params[0].value}</div> `;
            },
          },
          xAxis: {
            data: this.CurrencyTime,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value} BTC",
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0,
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 70,
              end: 100,
            },
            {
              start: 70,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          series: [
            {
              name: "持仓总量",
              data: this.inyData,
              type: "bar",
              itemStyle: {
                color: "#2EBD85",
              },
            },
          ],
        });
      }
    },
    //获取 大宗交易 XY轴data值
    chartData(type) {
      let defaultInstrument = this.defaultInstrument;
      if (type === 1) {
        let ScreenName =
          this.instrument_name && this.instrument_name.length !== 0
            ? this.ScreenName
            : this.defaultInstrument;
        this.$refs.BlockTransactions.chart.setOption({
          tooltip: {
            align: "axis",
            formatter: function (params) {
              return params[0].value
                ? `<div>${params[0].name}</div>
                          <div> ${ScreenName} <br><br> 交易额 ${params[0].value}万</div> `
                : `<div>${params[0].name}</div>
                          <div> ${ScreenName} <br><br> 交易额 ${params[0].value}万</div> `;
            },
          },
          xAxis: {
            data: this.XCharData,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}万 USD",
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0,
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          series: [
            {
              name: "交易额",
              data: this.YCharData,
              type: "bar",
              itemStyle: {
                color: "#2EBD85",
              },
            },
          ],
        });
      }
    },
    //获取对象,数组所有属性键值(key)
    getExpiryTime(object) {
      var keys = [];
      for (var property in object) keys.push(property);
      return keys;
    },
    //获取对象,数组所有属性对应值(value)
    getExpiryValue(object) {
      var values = [];
      for (var property in object) values.push(object[property]);
      return values;
    },
    //时间格式化
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
  },
};
</script>
<style lang="scss">
.card .card-header .card-title {
    color: #ffff !important;
}
.noData{
  color: #ffff !important;
}
</style>